@font-face {
  font-family: NotoSans;
  font-weight: 600;
  src: url(../fonts/NotoSans/NotoSans-Bold.woff2) format('woff2'),
    url(../fonts/NotoSans/NotoSans-Bold.woff) format('woff'),
    url(../fonts/NotoSans/NotoSans-Bold.otf) format('opentype'),
    url(../fonts/NotoSans/NotoSans-Bold.eot) format('embedded-opentype');
}

@font-face {
  font-family: NotoSans;
  font-weight: 500;
  src: url(../fonts/NotoSans/NotoSans-Medium.woff2) format('woff2'),
    url(../fonts/NotoSans/NotoSans-Medium.woff) format('woff'),
    url(../fonts/NotoSans/NotoSans-Medium.otf) format('opentype'),
    url(../fonts/NotoSans/NotoSans-Medium.eot) format('embedded-opentype');
}

@font-face {
  font-family: NotoSans;
  font-weight: 400;
  src: url(../fonts/NotoSans/NotoSans-Regular.woff2) format('woff2'),
    url(../fonts/NotoSans/NotoSans-Regular.woff) format('woff'),
    url(../fonts/NotoSans/NotoSans-Regular.otf) format('opentype'),
    url(../fonts/NotoSans/NotoSans-Regular.eot) format('embedded-opentype');
}

@font-face {
  font-family: NotoSans;
  font-weight: 300;
  src: url(../fonts/NotoSans/NotoSans-Light.woff2) format('woff2'),
    url(../fonts/NotoSans/NotoSans-Light.woff) format('woff'),
    url(../fonts/NotoSans/NotoSans-Light.otf) format('opentype'),
    url(../fonts/NotoSans/NotoSans-Light.eot) format('embedded-opentype');
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 900;
  src: url(../fonts/apple-sd-gothic-neo/AppleSDGothicNeoB.ttf) format('ttf');
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 700;
  src: url(../fonts/apple-sd-gothic-neo/AppleSDGothicNeoEB.ttf) format('ttf');
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 500;
  src: url(../fonts/apple-sd-gothic-neo/AppleSDGothicNeoH.ttf) format('ttf');
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 400;
  src: url(../fonts/apple-sd-gothic-neo/AppleSDGothicNeoL.ttf) format('ttf');
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 300;
  src: url(../fonts/apple-sd-gothic-neo/AppleSDGothicNeoM.ttf) format('ttf');
}

* {
  font-family: NotoSans, AppleSDGothicNeo, sans-serif;
}

html,
body {
  height: 100%;
  overflow: auto;
}

#root {
  height: 100%;
}

.flex {
  display: flex;
  flex-direction: column;
}

.main {
  flex: content;
  width: 100%;
}

.flex-fill {
  flex: 1 1 0 !important;
}

.app-body {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.content {
  padding-left: 5px;
  padding-right: 5px;
  min-width: 1350px;
  height: 100vh;
  background-color: #f3f8ff;
}

.btn-edit {
  position: relative;
  margin-left: auto;
  width: 24px;
  height: 24px;
  background: url('../images/icon/ic-moremenu.svg') no-repeat;
  border: 0;
}

.m-0 {
  margin: 0 !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.m-2 {
  margin: 2px !important;
}

.m-t-2 {
  margin-top: 2px !important;
}

.m-b-2 {
  margin-bottom: 2px !important;
}

.m-l-2 {
  margin-left: 2px !important;
}

.m-r-2 {
  margin-right: 2px !important;
}

.p-2 {
  padding: 2px !important;
}

.p-t-2 {
  padding-top: 2px !important;
}

.p-b-2 {
  padding-bottom: 2px !important;
}

.p-l-2 {
  padding-left: 2px !important;
}

.p-r-2 {
  padding-right: 2px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-t-4 {
  margin-top: 4px !important;
}

.m-b-4 {
  margin-bottom: 4px !important;
}

.m-l-4 {
  margin-left: 4px !important;
}

.m-r-4 {
  margin-right: 4px !important;
}

.p-4 {
  padding: 4px !important;
}

.p-t-4 {
  padding-top: 4px !important;
}

.p-b-4 {
  padding-bottom: 4px !important;
}

.p-l-4 {
  padding-left: 4px !important;
}

.p-r-4 {
  padding-right: 4px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-t-8 {
  margin-top: 8px !important;
}

.m-b-8 {
  margin-bottom: 8px !important;
}

.m-l-8 {
  margin-left: 8px !important;
}

.m-r-8 {
  margin-right: 8px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-t-8 {
  padding-top: 8px !important;
}

.p-b-8 {
  padding-bottom: 8px !important;
}

.p-l-8 {
  padding-left: 8px !important;
}

.p-r-8 {
  padding-right: 8px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-t-12 {
  margin-top: 12px !important;
}

.m-b-12 {
  margin-bottom: 12px !important;
}

.m-l-12 {
  margin-left: 12px !important;
}

.m-r-12 {
  margin-right: 12px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-t-12 {
  padding-top: 12px !important;
}

.p-b-12 {
  padding-bottom: 12px !important;
}

.p-l-12 {
  padding-left: 12px !important;
}

.p-r-12 {
  padding-right: 12px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-t-16 {
  margin-top: 16px !important;
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.m-l-16 {
  margin-left: 16px !important;
}

.m-r-16 {
  margin-right: 16px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-t-16 {
  padding-top: 16px !important;
}

.p-b-16 {
  padding-bottom: 16px !important;
}

.p-l-16 {
  padding-left: 16px !important;
}

.p-r-16 {
  padding-right: 16px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-t-24 {
  margin-top: 24px !important;
}

.m-b-24 {
  margin-bottom: 24px !important;
}

.m-l-24 {
  margin-left: 24px !important;
}

.m-r-24 {
  margin-right: 24px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-t-24 {
  padding-top: 24px !important;
}

.p-b-24 {
  padding-bottom: 24px !important;
}

.p-l-24 {
  padding-left: 24px !important;
}

.p-r-24 {
  padding-right: 24px !important;
}

.m-28 {
  margin: 28px !important;
}

.m-t-28 {
  margin-top: 28px !important;
}

.m-b-28 {
  margin-bottom: 28px !important;
}

.m-l-28 {
  margin-left: 28px !important;
}

.m-r-28 {
  margin-right: 28px !important;
}

.p-28 {
  padding: 28px !important;
}

.p-t-28 {
  padding-top: 28px !important;
}

.p-b-28 {
  padding-bottom: 28px !important;
}

.p-l-28 {
  padding-left: 28px !important;
}

.p-r-28 {
  padding-right: 28px !important;
}

.m-32 {
  margin: 32px !important;
}

.m-t-32 {
  margin-top: 32px !important;
}

.m-b-32 {
  margin-bottom: 32px !important;
}

.m-l-32 {
  margin-left: 32px !important;
}

.m-r-32 {
  margin-right: 32px !important;
}

.p-32 {
  padding: 32px !important;
}

.p-t-32 {
  padding-top: 32px !important;
}

.p-b-32 {
  padding-bottom: 32px !important;
}

.p-l-32 {
  padding-left: 32px !important;
}

.p-r-32 {
  padding-right: 32px !important;
}

.m-36 {
  margin: 36px !important;
}

.m-t-36 {
  margin-top: 36px !important;
}

.m-b-36 {
  margin-bottom: 36px !important;
}

.m-l-36 {
  margin-left: 36px !important;
}

.m-r-36 {
  margin-right: 36px !important;
}

.p-36 {
  padding: 36px !important;
}

.p-t-36 {
  padding-top: 36px !important;
}

.p-b-36 {
  padding-bottom: 36px !important;
}

.p-l-36 {
  padding-left: 36px !important;
}

.p-r-36 {
  padding-right: 36px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-19 {
  font-size: 19px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-23 {
  font-size: 23px !important;
}

.m-a {
  margin: auto;
}

.m-t-a {
  margin-top: auto !important;
}

.m-b-a {
  margin-bottom: auto !important;
}

.m-l-a {
  margin-left: auto !important;
}

.m-r-a {
  margin-right: auto !important;
}

.t-0 {
  top: 0 !important;
}

.f-300 {
  font-weight: 300 !important;
}

.f-400 {
  font-weight: 400 !important;
}

.f-500 {
  font-weight: 500 !important;
}

.f-700 {
  font-weight: 700 !important;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.o-hidden {
  overflow: hidden !important;
}

.o-visible {
  overflow: visible !important;
}

.o-auto {
  overflow: auto !important;
}

.c-text-dark {
  color: #434245 !important;
}

.bg-dark {
  background-color: #434245 !important;
}

.c-text {
  color: #646468 !important;
}

.bg-dark {
  background-color: #646468 !important;
}

.c-text-light {
  color: #7f7e83 !important;
}

.bg-light {
  background-color: #7f7e83 !important;
}

.c-text-lightest {
  color: #adacb0 !important;
}

.bg-lightest {
  background-color: #adacb0 !important;
}

.c-danger {
  color: #ff3d6b !important;
}

.bg-danger {
  background-color: #ff3d6b !important;
}

.c-primary {
  color: #0060ff !important;
}

.bg-primary {
  background-color: #400e40 !important;
}

.c-accent {
  color: #200e20 !important;
}

.bg-accent {
  background-color: #200e20 !important;
}

.c-success {
  color: #93da45 !important;
}

.bg-success {
  background-color: #93da45 !important;
}

.c-warning {
  color: #ffb302 !important;
}

.bg-warning {
  background-color: #ffb302 !important;
}

.c-info {
  color: #93da45 !important;
}

.bg-info {
  background-color: #93da45 !important;
}

.c-red {
  color: #fe3004 !important;
}

.bg-red {
  background-color: #fe3004 !important;
}

.c-blue {
  color: #0430fe !important;
}

.bg-blue {
  background-color: #0430fe !important;
}

.c-almost-white {
  color: #fafafa !important;
}

.bg-almost-white {
  background-color: #fafafa !important;
}

.c-black {
  color: #000 !important;
}

.bg-black {
  background-color: #000 !important;
}

.c-white {
  color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.b-50 {
  border-radius: 50%;
}

.w-100 {
  width: 100% !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-block {
  display: block !important;
}

/* .display-table {
  display: table !important;
} */

.display-none {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.text-center {
  text-align: center;
}

/*.checkbox {
    border-radius: 4px;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    text-align: center;
    background: #FFF;
    cursor: pointer;
}*/

.checkbox {
  border-radius: 4px;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  text-align: center;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.checkbox.checked {
  background: #fff;
}

.checkbox.disabled {
  background: #fafafa;
}

.checkbox-item-label {
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.zmdi {
  display: flex;
  font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zmdi-check {
  font-size: 14px;
  font-weight: 700;
  color: #400e40;
  transition: all 0.35s ease;
}

.zmdi-check:before {
  content: '';
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

button {
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  word-break: keep-all;
}

textarea {
  border-radius: 4px;
  border: 1px solid #e9e9ed;
  background-color: #f3f8ff;
  font-size: 16px;
  padding: 4px;
  width: 100%;
}

textarea::placeholder {
  color: #adacb0;
}
textarea.block {
  display: block;
  width: 100%;
}
textarea.image {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

button:disabled {
  opacity: 0.5 !important;
}

.pagination {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto !important;
  padding: 30px 0 30px 0;
  max-width: 80%;
  width: calc(100% - 270px);
}

.pagination .flex-row {
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.pagination .page-icon {
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 0;
  background-color: transparent;
  color: #a4b9d2;
  font-size: 18px;
  line-height: 1.4;
  vertical-align: middle;
}

.pagination .page-icon.number {
  cursor: pointer;
}

.pagination .page-icon:not(:last-child) {
  margin-right: 0;
}

.pagination .page-icon.selected {
  background-color: transparent;
  font-weight: 600;
  color: #273142;
  cursor: initial;
}

.pagination .page-icon.page-prev,
.pagination .page-icon.page-next {
  width: 26px;
  height: 26px;
  border-radius: 4px;
  border: solid 1px #dee2ec;
  background: url('../images/icon/icon_pagination_arrow.png') no-repeat;
  background-size: 8px;
  background-position: center center;
  background-color: #fff;
  cursor: pointer;
}

.pagination .page-icon.page-prev {
  margin-right: 20px;
  transform: rotate(180deg);
}

.pagination .page-icon.page-next {
  margin-left: 20px;
}

.pagination .page-icon.page-prev.disabled,
.pagination .page-icon.page-next.disabled {
  cursor: initial;
  background-image: url('../images/icon/icon_pagination_arrow_disable.png');
}

.pagination .page-total {
  position: absolute;
  left: 15px;
  top: 39px;
}

.pagination .page-total .tt {
  display: inline-block;
  margin-right: 5px;
  color: #9cb2cd;
}
select {
  border-radius: 4px;
  border: 1px solid #dee2ec;
  font-size: 14px;
  line-height: 1;
  padding: 4px;
  text-overflow: ellipsis;
}

.select-search {
  width: 300px;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
 * Input
 */
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
  background: #fff;
}

/**
 * Options
 */
.select-search__options {
  list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2fcc8b;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: gray;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 34px;
  right: 0;
  left: -40px;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}
