.main-contents {
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: 24px 20px;
  box-sizing: border-box;
}

.content-title {
  margin-top: 3px;
  margin-bottom: 24px;
  font-size: 17px;
  line-height: 17px;
  font-weight: 600;
}

.content-title-sub {
  color: #273142;
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  word-break: break-word;
  margin-bottom: 15px;
}

.search-bar {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  width: 100%;
}

.search-item {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 10px;
}

.search-item input:not([type='radio']):not([type='checkbox']) {
  width: 270px !important;
  min-height: 10px;
  height: 34px;
  border-radius: 4px;
  color: #000;
  font-size: 14px;
  background-image: url('../../images/icon/ic-search.png');
  padding: 8px 15px 8px 50px;
  border: 1px solid #dee2ec;
  background-color: #fff;
  background-size: 19px;
  background-repeat: no-repeat;
  background-position: left 15px center;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.main-contents table {
  width: 100%;
}

.main-contents table thead th {
  padding: 20px;
}

/* .main-contents table {
  background: #fff;
  border-spacing: 0;
  width: 100%;
  overflow: hidden;
  border-collapse: separate;
  text-indent: initial;
}

.scrollable-div {
  width: 100%;
  overflow: auto;
}

.scrollable-div table {
  min-width: 6000px;
}

.main-contents table th {
  font-family: NotoSans, sans-serif;
  color: #4a4f70;
  font-size: 14px;
  font-weight: 600;
  display: table-cell;
  vertical-align: inherit;
  text-align: center;
  padding: 20px;
  word-break: keep-all;
  overflow: hidden;
}

.main-contents table td {
  font-family: NotoSans, sans-serif;
  color: #4a4f70;
  font-size: 14px;
  font-weight: 500;
  display: table-cell;
  vertical-align: inherit;
  text-align: center;
  padding: 15px 5px 15px 5px;
  overflow: auto;
}

.main-contents table tr:not(:first-child) th {
  border-top: solid 1px #dee2ec;
}

.main-contents table .td-align-left {
  text-align: left;
}

.main-contents table .td-underline {
  text-decoration: #4a4f70 underline;
}

.clinic-use th {
  width: 100vw;
}

.scrollable-div table td.title-td {
  padding: 0;
} */
