.c-sidebar {
  position: relative;
  display: flex;
  flex: 0 0 256px;
  flex-direction: column;
  order: -1;
  width: 256px;
  padding: 0;
  box-shadow: none;
}
.c-sidebar.c-sidebar-right {
  order: 99;
}
@media (max-width: 991.98px) {
  .c-sidebar {
    --is-mobile: true;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1031;
  }
  html:not([dir='rtl']) .c-sidebar:not(.c-sidebar-right) {
    left: 0;
  }
  html:not([dir='rtl']) .c-sidebar.c-sidebar-right {
    right: 0;
  }
  [dir='rtl'] .c-sidebar:not(.c-sidebar-right) {
    right: 0;
  }
  [dir='rtl'] .c-sidebar.c-sidebar-right {
    left: 0;
  }
}
html:not([dir='rtl']) .c-sidebar:not(.c-sidebar-right) {
  margin-left: -256px;
}
html:not([dir='rtl']) .c-sidebar.c-sidebar-right {
  margin-right: -256px;
}
[dir='rtl'] .c-sidebar:not(.c-sidebar-right) {
  margin-right: -256px;
}
[dir='rtl'] .c-sidebar.c-sidebar-right {
  margin-left: -256px;
}
.c-sidebar[class*='bg-'] {
  border-color: rgba(0, 0, 21, 0.1);
}
.c-sidebar.c-sidebar-sm {
  flex: 0 0 192px;
  width: 192px;
}
html:not([dir='rtl']) .c-sidebar.c-sidebar-sm:not(.c-sidebar-right) {
  margin-left: -192px;
}
html:not([dir='rtl']) .c-sidebar.c-sidebar-sm.c-sidebar-right {
  margin-right: -192px;
}
[dir='rtl'] .c-sidebar.c-sidebar-sm:not(.c-sidebar-right) {
  margin-right: -192px;
}
[dir='rtl'] .c-sidebar.c-sidebar-sm.c-sidebar-right {
  margin-left: -192px;
}
.c-sidebar.c-sidebar-lg {
  flex: 0 0 320px;
  width: 320px;
}
html:not([dir='rtl']) .c-sidebar.c-sidebar-lg:not(.c-sidebar-right) {
  margin-left: -320px;
}
html:not([dir='rtl']) .c-sidebar.c-sidebar-lg.c-sidebar-right {
  margin-right: -320px;
}
[dir='rtl'] .c-sidebar.c-sidebar-lg:not(.c-sidebar-right) {
  margin-right: -320px;
}
[dir='rtl'] .c-sidebar.c-sidebar-lg.c-sidebar-right {
  margin-left: -320px;
}
.c-sidebar.c-sidebar-xl {
  flex: 0 0 384px;
  width: 384px;
}
html:not([dir='rtl']) .c-sidebar.c-sidebar-xl:not(.c-sidebar-right) {
  margin-left: -384px;
}
html:not([dir='rtl']) .c-sidebar.c-sidebar-xl.c-sidebar-right {
  margin-right: -384px;
}
[dir='rtl'] .c-sidebar.c-sidebar-xl:not(.c-sidebar-right) {
  margin-right: -384px;
}
[dir='rtl'] .c-sidebar.c-sidebar-xl.c-sidebar-right {
  margin-left: -384px;
}
@media (min-width: 992px) {
  .c-sidebar.c-sidebar-fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1030;
  }
  html:not([dir='rtl']) .c-sidebar.c-sidebar-fixed:not(.c-sidebar-right) {
    left: 0;
  }
  html:not([dir='rtl']) .c-sidebar.c-sidebar-fixed.c-sidebar-right {
    right: 0;
  }
  [dir='rtl'] .c-sidebar.c-sidebar-fixed:not(.c-sidebar-right) {
    right: 0;
  }
  [dir='rtl'] .c-sidebar.c-sidebar-fixed.c-sidebar-right {
    left: 0;
  }
}
.c-sidebar.c-sidebar-overlaid {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1032;
}
html:not([dir='rtl']) .c-sidebar.c-sidebar-overlaid:not(.c-sidebar-right) {
  left: 0;
}
html:not([dir='rtl']) .c-sidebar.c-sidebar-overlaid.c-sidebar-right {
  right: 0;
}
[dir='rtl'] .c-sidebar.c-sidebar-overlaid:not(.c-sidebar-right) {
  right: 0;
}
[dir='rtl'] .c-sidebar.c-sidebar-overlaid.c-sidebar-right {
  left: 0;
}
.c-sidebar-close {
  position: absolute;
  width: 56px;
  height: 56px;
  background: 0 0;
  border: 0;
}
@media (hover: hover), (-ms-high-contrast: none) {
  .c-sidebar-close:hover {
    text-decoration: none;
  }
}
.c-sidebar-close.focus,
.c-sidebar-close:focus {
  outline: 0;
}
html:not([dir='rtl']) .c-sidebar-close {
  right: 0;
}
[dir='rtl'] .c-sidebar-close {
  left: 0;
}
.c-sidebar-brand {
  display: flex;
  flex: 0 0 56px;
  align-items: center;
  justify-content: center;
}
.c-sidebar-brand .c-sidebar-brand-minimized {
  display: none;
}
.c-sidebar-header {
  flex: 0 0 auto;
  padding: 0.75rem 1rem;
  text-align: center;
  transition: 0.3s;
}
.c-sidebar-nav {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
}
.c-sidebar-nav.ps {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.c-sidebar-nav.ps::-webkit-scrollbar {
  width: 0 !important;
}
.c-sidebar-nav-title {
  padding: 0.75rem 1rem;
  margin-top: 1rem;
  font-size: 80%;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.3s;
}
.c-sidebar-nav-divider {
  height: 10px;
  transition: height 0.3s;
}
.c-sidebar-nav-item {
  width: inherit;
}
.c-sidebar-nav-dropdown-toggle,
.c-sidebar-nav-link {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0.8445rem 1rem;
  text-decoration: none;
  white-space: nowrap;
  transition: background 0.3s, color 0.3s;
}
html:not([dir='rtl']) .c-sidebar-nav-dropdown-toggle .badge,
html:not([dir='rtl']) .c-sidebar-nav-link .badge {
  margin-left: auto;
}
[dir='rtl'] .c-sidebar-nav-dropdown-toggle .badge,
[dir='rtl'] .c-sidebar-nav-link .badge {
  margin-right: auto;
}
.c-disabled.c-sidebar-nav-dropdown-toggle,
.c-sidebar-nav-link.c-disabled {
  cursor: default;
}
@media (hover: hover), (-ms-high-contrast: none) {
  .c-sidebar-nav-dropdown-toggle:hover,
  .c-sidebar-nav-link:hover {
    text-decoration: none;
  }
}
.c-sidebar-nav-icon {
  flex: 0 0 56px;
  height: 1.09375rem;
  font-size: 1.09375rem;
  text-align: center;
  transition: 0.3s;
  fill: currentColor;
}
html:not([dir='rtl']) .c-sidebar-nav-icon:first-child {
  margin-left: -1rem;
}
[dir='rtl'] .c-sidebar-nav-icon:first-child {
  margin-right: -1rem;
}
.c-sidebar-nav-dropdown {
  position: relative;
  transition: background 0.3s ease-in-out;
}
.c-sidebar-nav-dropdown.c-show > .c-sidebar-nav-dropdown-items {
  max-height: 1500px;
}
html:not([dir='rtl'])
  .c-sidebar-nav-dropdown.c-show
  > .c-sidebar-nav-dropdown-toggle::after {
  transform: rotate(-90deg);
}
[dir='rtl']
  .c-sidebar-nav-dropdown.c-show
  > .c-sidebar-nav-dropdown-toggle::after {
  transform: rotate(270deg);
}
.c-sidebar-nav-dropdown.c-show + .c-sidebar-nav-dropdown.c-show {
  margin-top: 1px;
}
.c-sidebar-nav-dropdown-toggle {
  cursor: pointer;
}
.c-sidebar-nav-dropdown-toggle::after {
  display: block;
  flex: 0 8px;
  height: 8px;
  content: '';
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.3s;
}
html:not([dir='rtl']) .c-sidebar-nav-dropdown-toggle::after {
  margin-left: auto;
}
[dir='rtl'] .c-sidebar-nav-dropdown-toggle::after {
  margin-right: auto;
  transform: rotate(180deg);
}
html:not([dir='rtl']) .c-sidebar-nav-dropdown-toggle .badge {
  margin-right: 1rem;
}
[dir='rtl'] .c-sidebar-nav-dropdown-toggle .badge {
  margin-left: 1rem;
}
.c-sidebar-nav-dropdown-items {
  max-height: 0;
  padding: 0;
  overflow-y: hidden;
  list-style: none;
  transition: max-height 0.3s ease-in-out;
}
html:not([dir='rtl'])
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle,
html:not([dir='rtl']) .c-sidebar-nav-dropdown-items .c-sidebar-nav-link {
  padding-left: 56px;
}
[dir='rtl'] .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle,
[dir='rtl'] .c-sidebar-nav-dropdown-items .c-sidebar-nav-link {
  padding-right: 56px;
}
html:not([dir='rtl'])
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
html:not([dir='rtl'])
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link
  .c-sidebar-nav-icon {
  margin-left: -56px;
}
[dir='rtl']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
[dir='rtl']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link
  .c-sidebar-nav-icon {
  margin-right: -56px;
}
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle,
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link {
  padding-left: 64px;
}
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle,
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link {
  padding-right: 64px;
}
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link
  .c-sidebar-nav-icon {
  margin-left: -56px;
}
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link
  .c-sidebar-nav-icon {
  margin-right: -56px;
}
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle,
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link {
  padding-left: 72px;
}
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle,
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link {
  padding-right: 72px;
}
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link
  .c-sidebar-nav-icon {
  margin-left: -56px;
}
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link
  .c-sidebar-nav-icon {
  margin-right: -56px;
}
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle,
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link {
  padding-left: 80px;
}
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle,
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link {
  padding-right: 80px;
}
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link
  .c-sidebar-nav-icon {
  margin-left: -56px;
}
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link
  .c-sidebar-nav-icon {
  margin-right: -56px;
}
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle,
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link {
  padding-left: 88px;
}
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle,
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link {
  padding-right: 88px;
}
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link
  .c-sidebar-nav-icon {
  margin-left: -56px;
}
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link
  .c-sidebar-nav-icon {
  margin-right: -56px;
}
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle,
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link {
  padding-left: 96px;
}
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle,
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link {
  padding-right: 96px;
}
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
html:not([dir='rtl'])
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link
  .c-sidebar-nav-icon {
  margin-left: -56px;
}
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
[dir='rtl']
  .c-sidebar-nav[data-indentation='true']
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-link
  .c-sidebar-nav-icon {
  margin-right: -56px;
}
.c-sidebar-nav-label {
  display: flex;
  padding: 0.211125rem 1rem;
  transition: 0.3s;
}
.c-sidebar-nav-label:hover {
  text-decoration: none;
}
.c-sidebar-nav-label .c-sidebar-nav-icon {
  margin-top: 1px;
}
.c-sidebar-footer {
  flex: 0 0 auto;
  padding: 0.75rem 1rem;
  transition: 0.3s;
}
.c-sidebar-minimizer {
  display: flex;
  flex: 0 0 50px;
  justify-content: flex-end;
  width: inherit;
  padding: 0;
  cursor: pointer;
  border: 0;
}
@media (max-width: 991.98px) {
  .c-sidebar-minimizer {
    display: none;
  }
}
.c-sidebar-minimizer::before {
  display: block;
  width: 50px;
  height: 50px;
  content: '';
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12.5px;
  transition: 0.3s;
}
[dir='rtl'] .c-sidebar-minimizer::before {
  transform: rotate(180deg);
}
.c-sidebar-minimizer.c-focus,
.c-sidebar-minimizer:focus {
  outline: 0;
}
.c-sidebar-right .c-sidebar-minimizer {
  justify-content: flex-start;
}
html:not([dir='rtl']) .c-sidebar-right .c-sidebar-minimizer::before {
  transform: rotate(-180deg);
}
[dir='rtl'] .c-sidebar-right .c-sidebar-minimizer::before {
  transform: rotate(0);
}
@media (max-width: 991.98px) {
  .c-sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1030;
    width: 100vw;
    height: 100vh;
    background-color: #000015;
    transition: 0.3s;
  }
  .c-sidebar-backdrop.c-fade {
    opacity: 0;
  }
  .c-sidebar-backdrop.c-show {
    opacity: 0.5;
  }
}
@media (min-width: 992px) {
  .c-sidebar-minimized {
    z-index: 1031;
    flex: 0 0 56px;
  }
  .c-sidebar-minimized.c-sidebar-fixed {
    z-index: 1031;
    width: 56px;
  }
  html:not([dir='rtl']) .c-sidebar-minimized:not(.c-sidebar-right) {
    margin-left: -56px;
  }
  [dir='rtl'] .c-sidebar-minimized:not(.c-sidebar-right) {
    margin-right: -56px;
  }
  html:not([dir='rtl']) .c-sidebar-minimized.c-sidebar-right {
    margin-right: -56px;
  }
  html:not([dir='rtl']) .c-sidebar-minimized.c-sidebar-right {
    margin-left: -56px;
  }
  .c-sidebar-minimized .c-sidebar-brand-full {
    display: none;
  }
  .c-sidebar-minimized .c-sidebar-brand-minimized {
    display: block;
  }
  .c-sidebar-minimized .c-sidebar-nav {
    padding-bottom: 50px;
    overflow: visible;
  }
  .c-sidebar-minimized .c-d-minimized-none,
  .c-sidebar-minimized .c-sidebar-footer,
  .c-sidebar-minimized .c-sidebar-form,
  .c-sidebar-minimized .c-sidebar-header,
  .c-sidebar-minimized .c-sidebar-nav-divider,
  .c-sidebar-minimized .c-sidebar-nav-label,
  .c-sidebar-minimized .c-sidebar-nav-title {
    height: 0;
    padding: 0;
    margin: 0;
    visibility: hidden;
    opacity: 0;
  }
  .c-sidebar-minimized .c-sidebar-minimizer {
    position: fixed;
    bottom: 0;
    width: inherit;
  }
  html:not([dir='rtl']) .c-sidebar-minimized .c-sidebar-minimizer::before {
    transform: rotate(-180deg);
  }
  [dir='rtl'] .c-sidebar-minimized .c-sidebar-minimizer::before {
    transform: rotate(0);
  }
  html:not([dir='rtl'])
    .c-sidebar-minimized.c-sidebar-right
    .c-sidebar-minimizer::before {
    transform: rotate(0);
  }
  [dir='rtl']
    .c-sidebar-minimized.c-sidebar-right
    .c-sidebar-minimizer::before {
    transform: rotate(180deg);
  }
  html:not([dir='rtl'])
    .c-sidebar-minimized.c-sidebar-right
    .c-sidebar-nav
    > .c-sidebar-nav-dropdown:hover,
  html:not([dir='rtl'])
    .c-sidebar-minimized.c-sidebar-right
    .c-sidebar-nav
    > .c-sidebar-nav-item:hover {
    margin-left: -256px;
  }
  [dir='rtl']
    .c-sidebar-minimized.c-sidebar-right
    .c-sidebar-nav
    > .c-sidebar-nav-dropdown:hover,
  [dir='rtl']
    .c-sidebar-minimized.c-sidebar-right
    .c-sidebar-nav
    > .c-sidebar-nav-item:hover {
    margin-right: -256px;
  }
  .c-sidebar-minimized .c-sidebar-nav-dropdown-toggle,
  .c-sidebar-minimized .c-sidebar-nav-link {
    overflow: hidden;
    white-space: nowrap;
    border-left: 0;
  }
  .c-sidebar-minimized .c-sidebar-nav-dropdown-toggle:hover,
  .c-sidebar-minimized .c-sidebar-nav-link:hover {
    width: 312px;
  }
  .c-sidebar-minimized .c-sidebar-nav-dropdown-toggle::after {
    display: none;
  }
  .c-sidebar-minimized
    .c-sidebar-nav-dropdown-items
    .c-sidebar-nav-dropdown-toggle,
  .c-sidebar-minimized .c-sidebar-nav-dropdown-items .c-sidebar-nav-link {
    width: 256px;
  }
  .c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown {
    position: relative;
  }
  .c-sidebar-minimized
    .c-sidebar-nav
    > .c-sidebar-nav-dropdown
    > .c-sidebar-nav-dropdown-items {
    display: none;
  }
  .c-sidebar-minimized
    .c-sidebar-nav
    > .c-sidebar-nav-dropdown
    > .c-sidebar-nav-dropdown-items
    .c-sidebar-nav-dropdown:not(.c-show)
    > .c-sidebar-nav-dropdown-items {
    display: none;
  }
  .c-sidebar-minimized
    .c-sidebar-nav
    > .c-sidebar-nav-dropdown
    .c-sidebar-nav-dropdown-items {
    max-height: 1500px;
  }
  .c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
    width: 312px;
    overflow: visible;
  }
  .c-sidebar-minimized
    .c-sidebar-nav
    > .c-sidebar-nav-dropdown:hover
    > .c-sidebar-nav-dropdown-items {
    position: absolute;
    display: inline;
  }
  html:not([dir='rtl'])
    .c-sidebar-minimized
    .c-sidebar-nav
    > .c-sidebar-nav-dropdown:hover
    > .c-sidebar-nav-dropdown-items {
    left: 56px;
  }
  [dir='rtl']
    .c-sidebar-minimized
    .c-sidebar-nav
    > .c-sidebar-nav-dropdown:hover
    > .c-sidebar-nav-dropdown-items {
    right: 56px;
  }
  html:not([dir='rtl'])
    .c-sidebar-minimized.c-sidebar-right
    > .c-sidebar-nav-dropdown:hover
    > .c-sidebar-nav-dropdown-items {
    left: 0;
  }
  [dir='rtl']
    .c-sidebar-minimized.c-sidebar-right
    > .c-sidebar-nav-dropdown:hover
    > .c-sidebar-nav-dropdown-items {
    right: 0;
  }
}
html:not([dir='rtl']) .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
  margin-left: 0;
}
[dir='rtl'] .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
  margin-right: 0;
}
@media (min-width: 992px) {
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 256px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-right: 256px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-left: 192px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-right: 192px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-left: 320px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-right: 320px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-left: 384px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-right: 384px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-left: 56px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-right: 56px;
  }
}
html:not([dir='rtl']) .c-sidebar.c-sidebar-show.c-sidebar-right {
  margin-right: 0;
}
[dir='rtl'] .c-sidebar.c-sidebar-show.c-sidebar-right {
  margin-left: 0;
}
@media (min-width: 992px) {
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper {
    margin-right: 256px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 256px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-right: 192px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-left: 192px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-right: 320px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-left: 320px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-right: 384px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-left: 384px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-right: 56px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-left: 56px;
  }
}
@media (min-width: 576px) {
  html:not([dir='rtl']) .c-sidebar.c-sidebar-show:not(.c-sidebar-right),
  html:not([dir='rtl']) .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right) {
    margin-left: 0;
  }
  [dir='rtl'] .c-sidebar.c-sidebar-show:not(.c-sidebar-right),
  [dir='rtl'] .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right) {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (min-width: 992px) {
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 256px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-right: 256px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-sm-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-left: 192px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-sm-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-right: 192px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-sm-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-left: 320px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-sm-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-right: 320px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-sm-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-left: 384px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-sm-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-right: 384px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-sm-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-left: 56px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-sm-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-right: 56px;
  }
}
@media (min-width: 576px) {
  html:not([dir='rtl']) .c-sidebar.c-sidebar-show.c-sidebar-right,
  html:not([dir='rtl']) .c-sidebar.c-sidebar-sm-show.c-sidebar-right {
    margin-right: 0;
  }
  [dir='rtl'] .c-sidebar.c-sidebar-show.c-sidebar-right,
  [dir='rtl'] .c-sidebar.c-sidebar-sm-show.c-sidebar-right {
    margin-left: 0;
  }
}
@media (min-width: 576px) and (min-width: 992px) {
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper {
    margin-right: 256px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 256px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-right: 192px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-left: 192px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-right: 320px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-left: 320px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-right: 384px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-left: 384px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-right: 56px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-left: 56px;
  }
}
@media (min-width: 768px) {
  html:not([dir='rtl']) .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right),
  html:not([dir='rtl']) .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
    margin-left: 0;
  }
  [dir='rtl'] .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right),
  [dir='rtl'] .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 256px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-right: 256px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-md-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-left: 192px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-md-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-right: 192px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-md-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-left: 320px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-md-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-right: 320px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-md-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-left: 384px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-md-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-right: 384px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-md-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-left: 56px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-md-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-right: 56px;
  }
}
@media (min-width: 768px) {
  html:not([dir='rtl']) .c-sidebar.c-sidebar-md-show.c-sidebar-right,
  html:not([dir='rtl']) .c-sidebar.c-sidebar-show.c-sidebar-right {
    margin-right: 0;
  }
  [dir='rtl'] .c-sidebar.c-sidebar-md-show.c-sidebar-right,
  [dir='rtl'] .c-sidebar.c-sidebar-show.c-sidebar-right {
    margin-left: 0;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper {
    margin-right: 256px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 256px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-right: 192px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-left: 192px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-right: 320px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-left: 320px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-right: 384px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-left: 384px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-right: 56px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-left: 56px;
  }
}
@media (min-width: 992px) {
  html:not([dir='rtl']) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right),
  html:not([dir='rtl']) .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
    margin-left: 0;
  }
  [dir='rtl'] .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right),
  [dir='rtl'] .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
    margin-right: 0;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 256px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-right: 256px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-lg-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-left: 192px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-lg-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-right: 192px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-lg-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-left: 320px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-lg-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-right: 320px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-lg-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-left: 384px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-lg-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-right: 384px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-lg-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-left: 56px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-lg-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-right: 56px;
  }
}
@media (min-width: 992px) {
  html:not([dir='rtl']) .c-sidebar.c-sidebar-lg-show.c-sidebar-right,
  html:not([dir='rtl']) .c-sidebar.c-sidebar-show.c-sidebar-right {
    margin-right: 0;
  }
  [dir='rtl'] .c-sidebar.c-sidebar-lg-show.c-sidebar-right,
  [dir='rtl'] .c-sidebar.c-sidebar-show.c-sidebar-right {
    margin-left: 0;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper {
    margin-right: 256px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 256px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-right: 192px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-left: 192px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-right: 320px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-left: 320px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-right: 384px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-left: 384px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-right: 56px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-left: 56px;
  }
}
@media (min-width: 1200px) {
  html:not([dir='rtl']) .c-sidebar.c-sidebar-show:not(.c-sidebar-right),
  html:not([dir='rtl']) .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right) {
    margin-left: 0;
  }
  [dir='rtl'] .c-sidebar.c-sidebar-show:not(.c-sidebar-right),
  [dir='rtl'] .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right) {
    margin-right: 0;
  }
}
@media (min-width: 1200px) and (min-width: 992px) {
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 256px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-right: 256px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-left: 192px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-right: 192px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-left: 320px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-right: 320px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-left: 384px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-right: 384px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-left: 56px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-right: 56px;
  }
}
@media (min-width: 1200px) {
  html:not([dir='rtl']) .c-sidebar.c-sidebar-show.c-sidebar-right,
  html:not([dir='rtl']) .c-sidebar.c-sidebar-xl-show.c-sidebar-right {
    margin-right: 0;
  }
  [dir='rtl'] .c-sidebar.c-sidebar-show.c-sidebar-right,
  [dir='rtl'] .c-sidebar.c-sidebar-xl-show.c-sidebar-right {
    margin-left: 0;
  }
}
@media (min-width: 1200px) and (min-width: 992px) {
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper {
    margin-right: 256px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 256px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-right: 192px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-left: 192px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-right: 320px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-left: 320px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-right: 384px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-left: 384px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-right: 56px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-left: 56px;
  }
}
@media (min-width: 1400px) {
  html:not([dir='rtl']) .c-sidebar.c-sidebar-show:not(.c-sidebar-right),
  html:not([dir='rtl']) .c-sidebar.c-sidebar-xxl-show:not(.c-sidebar-right) {
    margin-left: 0;
  }
  [dir='rtl'] .c-sidebar.c-sidebar-show:not(.c-sidebar-right),
  [dir='rtl'] .c-sidebar.c-sidebar-xxl-show:not(.c-sidebar-right) {
    margin-right: 0;
  }
}
@media (min-width: 1400px) and (min-width: 992px) {
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xxl-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 256px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xxl-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-right: 256px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xxl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-left: 192px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xxl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-right: 192px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xxl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-left: 320px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xxl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-right: 320px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xxl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-left: 384px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xxl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-right: 384px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xxl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-left: 56px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xxl-show:not(
      .c-sidebar-right
    ).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-right: 56px;
  }
}
@media (min-width: 1400px) {
  html:not([dir='rtl']) .c-sidebar.c-sidebar-show.c-sidebar-right,
  html:not([dir='rtl']) .c-sidebar.c-sidebar-xxl-show.c-sidebar-right {
    margin-right: 0;
  }
  [dir='rtl'] .c-sidebar.c-sidebar-show.c-sidebar-right,
  [dir='rtl'] .c-sidebar.c-sidebar-xxl-show.c-sidebar-right {
    margin-left: 0;
  }
}
@media (min-width: 1400px) and (min-width: 992px) {
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xxl-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper {
    margin-right: 256px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xxl-show.c-sidebar-right.c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 256px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xxl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-right: 192px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xxl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm
    ~ .c-wrapper {
    margin-left: 192px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xxl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-right: 320px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xxl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg
    ~ .c-wrapper {
    margin-left: 320px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xxl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-right: 384px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xxl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl
    ~ .c-wrapper {
    margin-left: 384px;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-xxl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-right: 56px;
  }
  [dir='rtl']
    .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  [dir='rtl']
    .c-sidebar.c-sidebar-xxl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-left: 56px;
  }
}
.c-sidebar {
  color: #fff;
  background: #3c4b64;
}
[dir='rtl'] .c-sidebar.c-sidebar-right {
  border: 0;
}
.c-sidebar .c-sidebar-close {
  color: #fff;
}
.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: rgba(0, 0, 21, 0.2);
}
.c-sidebar .c-sidebar-header {
  background: rgba(0, 0, 21, 0.2);
}
.c-sidebar .c-sidebar-form .c-form-control {
  color: #fff;
  background: rgba(0, 0, 21, 0.1);
  border: 0;
}
.c-sidebar .c-sidebar-form .c-form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.c-sidebar .c-sidebar-nav-title {
  color: rgba(255, 255, 255, 0.6);
}
.c-sidebar .c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link {
  color: rgba(255, 255, 255, 0.8);
  background: 0 0;
}
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link.c-active {
  color: #fff;
  background: rgba(255, 255, 255, 0.05);
}
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon {
  color: #fff;
}
.c-sidebar .c-sidebar-nav-dropdown-toggle:focus,
.c-sidebar .c-sidebar-nav-link:focus {
  outline: 0;
}
@media (hover: hover), (-ms-high-contrast: none) {
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover,
  .c-sidebar .c-sidebar-nav-link:hover {
    color: #fff;
    background: #321fdb;
  }
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon,
  .c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon {
    color: #fff;
  }
  .c-sidebar .c-sidebar-nav-link:hover.c-sidebar-nav-dropdown-toggle::after,
  .c-sidebar :hover.c-sidebar-nav-dropdown-toggle::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  }
}
.c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link.c-disabled {
  color: #b3b3b3;
  background: 0 0;
}
.c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link.c-disabled .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}
.c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-link.c-disabled:hover {
  color: #b3b3b3;
}
.c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link.c-disabled:hover .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}
.c-sidebar .c-disabled:hover.c-sidebar-nav-dropdown-toggle::after,
.c-sidebar
  .c-sidebar-nav-link.c-disabled:hover.c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.c-sidebar .c-sidebar-nav-dropdown-toggle {
  position: relative;
}
.c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(255, 255, 255, 0.5)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: rgba(0, 0, 0, 0.2);
}
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link {
  color: #fff;
}
.c-sidebar
  .c-sidebar-nav-dropdown.c-show
  .c-disabled.c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled {
  color: #b3b3b3;
  background: 0 0;
}
.c-sidebar
  .c-sidebar-nav-dropdown.c-show
  .c-disabled.c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled:hover {
  color: #b3b3b3;
}
.c-sidebar
  .c-sidebar-nav-dropdown.c-show
  .c-disabled.c-sidebar-nav-dropdown-toggle:hover
  .c-sidebar-nav-icon,
.c-sidebar
  .c-sidebar-nav-dropdown.c-show
  .c-sidebar-nav-link.c-disabled:hover
  .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}
.c-sidebar .c-sidebar-nav-label {
  color: rgba(255, 255, 255, 0.6);
}
.c-sidebar .c-sidebar-nav-label:hover {
  color: #fff;
}
.c-sidebar .c-sidebar-nav-label .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}
.c-sidebar .c-progress {
  background-color: #596f94 !important;
}
.c-sidebar .c-sidebar-footer {
  background: rgba(31, 31, 113, 0.2);
}
.c-sidebar .c-sidebar-minimizer {
  background-color: rgba(0, 0, 21, 0.2);
}
.c-sidebar .c-sidebar-minimizer::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%238a93a2' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.c-sidebar .c-sidebar-minimizer.c-focus,
.c-sidebar .c-sidebar-minimizer:focus {
  outline: 0;
}
.c-sidebar .c-sidebar-minimizer:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.c-sidebar .c-sidebar-minimizer:hover::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-link
  .c-sidebar-nav-icon {
  color: #fff;
}
.c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-disabled.c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-link.c-disabled {
  background: #3c4b64;
}
.c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-disabled.c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-link.c-disabled
  .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}
.c-sidebar.c-sidebar-minimized
  .c-sidebar-nav
  > .c-sidebar-nav-dropdown
  > .c-sidebar-nav-dropdown-items {
  background: #3c4b64;
}
.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
  background: #321fdb;
}
.c-sidebar.c-sidebar-light {
  color: #3c4b64;
  background: #fff;
  border-right: 1px solid rgba(159, 167, 179, 0.5);
}
html:not([dir='rtl']) .c-sidebar.c-sidebar-light.c-sidebar-right {
  border-right: 0;
  border-left: 1px solid rgba(159, 167, 179, 0.5);
}
[dir='rtl'] .c-sidebar.c-sidebar-light {
  border-right: 0;
  border-left: 1px solid rgba(159, 167, 179, 0.5);
}
[dir='rtl'] .c-sidebar.c-sidebar-light.c-sidebar-right {
  border: 0;
  border-right: 1px solid rgba(159, 167, 179, 0.5);
}
.c-sidebar.c-sidebar-light .c-sidebar-close {
  color: #3c4b64;
}
.c-sidebar.c-sidebar-light .c-sidebar-brand {
  color: #fff;
  background: #321fdb;
}
.c-sidebar.c-sidebar-light .c-sidebar-header {
  background: rgba(0, 0, 21, 0.2);
}
.c-sidebar.c-sidebar-light .c-sidebar-form .c-form-control {
  color: #fff;
  background: rgba(0, 0, 21, 0.1);
  border: 0;
}
.c-sidebar.c-sidebar-light .c-sidebar-form .c-form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.c-sidebar.c-sidebar-light .c-sidebar-nav-title {
  color: rgba(0, 0, 21, 0.4);
}
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light .c-sidebar-nav-link {
  color: rgba(0, 0, 21, 0.8);
  background: 0 0;
}
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light .c-sidebar-nav-link .c-sidebar-nav-icon {
  color: rgba(0, 0, 21, 0.5);
}
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active {
  color: rgba(0, 0, 21, 0.8);
  background: rgba(0, 0, 21, 0.05);
}
.c-sidebar.c-sidebar-light
  .c-active.c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active .c-sidebar-nav-icon {
  color: #321fdb;
}
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:focus,
.c-sidebar.c-sidebar-light .c-sidebar-nav-link:focus {
  outline: 0;
}
@media (hover: hover), (-ms-high-contrast: none) {
  .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover,
  .c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover {
    color: #fff;
    background: #321fdb;
  }
  .c-sidebar.c-sidebar-light
    .c-sidebar-nav-dropdown-toggle:hover
    .c-sidebar-nav-icon,
  .c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover .c-sidebar-nav-icon {
    color: #fff;
  }
  .c-sidebar.c-sidebar-light
    .c-sidebar-nav-link:hover.c-sidebar-nav-dropdown-toggle::after,
  .c-sidebar.c-sidebar-light :hover.c-sidebar-nav-dropdown-toggle::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  }
}
.c-sidebar.c-sidebar-light .c-disabled.c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-disabled {
  color: #b3b3b3;
  background: 0 0;
}
.c-sidebar.c-sidebar-light
  .c-disabled.c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-disabled .c-sidebar-nav-icon {
  color: rgba(0, 0, 21, 0.5);
}
.c-sidebar.c-sidebar-light .c-disabled.c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-disabled:hover {
  color: #b3b3b3;
}
.c-sidebar.c-sidebar-light
  .c-disabled.c-sidebar-nav-dropdown-toggle:hover
  .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light
  .c-sidebar-nav-link.c-disabled:hover
  .c-sidebar-nav-icon {
  color: rgba(0, 0, 21, 0.5);
}
.c-sidebar.c-sidebar-light
  .c-disabled:hover.c-sidebar-nav-dropdown-toggle::after,
.c-sidebar.c-sidebar-light
  .c-sidebar-nav-link.c-disabled:hover.c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle {
  position: relative;
}
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(0, 0, 21, 0.5)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show {
  background: rgba(0, 0, 0, 0.05);
}
.c-sidebar.c-sidebar-light
  .c-sidebar-nav-dropdown.c-show
  .c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link {
  color: rgba(0, 0, 21, 0.8);
}
.c-sidebar.c-sidebar-light
  .c-sidebar-nav-dropdown.c-show
  .c-disabled.c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light
  .c-sidebar-nav-dropdown.c-show
  .c-sidebar-nav-link.c-disabled {
  color: #b3b3b3;
  background: 0 0;
}
.c-sidebar.c-sidebar-light
  .c-sidebar-nav-dropdown.c-show
  .c-disabled.c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar.c-sidebar-light
  .c-sidebar-nav-dropdown.c-show
  .c-sidebar-nav-link.c-disabled:hover {
  color: #b3b3b3;
}
.c-sidebar.c-sidebar-light
  .c-sidebar-nav-dropdown.c-show
  .c-disabled.c-sidebar-nav-dropdown-toggle:hover
  .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light
  .c-sidebar-nav-dropdown.c-show
  .c-sidebar-nav-link.c-disabled:hover
  .c-sidebar-nav-icon {
  color: rgba(0, 0, 21, 0.5);
}
.c-sidebar.c-sidebar-light .c-sidebar-nav-label {
  color: rgba(0, 0, 21, 0.4);
}
.c-sidebar.c-sidebar-light .c-sidebar-nav-label:hover {
  color: #3c4b64;
}
.c-sidebar.c-sidebar-light .c-sidebar-nav-label .c-sidebar-nav-icon {
  color: rgba(0, 0, 21, 0.5);
}
.c-sidebar.c-sidebar-light .c-sidebar-footer {
  background: rgba(0, 0, 21, 0.2);
}
.c-sidebar.c-sidebar-light .c-sidebar-minimizer {
  background-color: rgba(0, 0, 0, 0.05);
}
.c-sidebar.c-sidebar-light .c-sidebar-minimizer::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%238a93a2' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.c-sidebar.c-sidebar-light .c-sidebar-minimizer.c-focus,
.c-sidebar.c-sidebar-light .c-sidebar-minimizer:focus {
  outline: 0;
}
.c-sidebar.c-sidebar-light .c-sidebar-minimizer:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.c-sidebar.c-sidebar-light .c-sidebar-minimizer:hover::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23768192' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.c-sidebar.c-sidebar-light.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-link {
  background: #321fdb;
}
.c-sidebar.c-sidebar-light.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-link
  .c-sidebar-nav-icon {
  color: #fff;
}
.c-sidebar.c-sidebar-light.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-disabled.c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-link.c-disabled {
  background: #fff;
}
.c-sidebar.c-sidebar-light.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-disabled.c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-link.c-disabled
  .c-sidebar-nav-icon {
  color: rgba(0, 0, 21, 0.5);
}
.c-sidebar.c-sidebar-light.c-sidebar-minimized
  .c-sidebar-nav
  > .c-sidebar-nav-dropdown
  > .c-sidebar-nav-dropdown-items {
  background: #fff;
}
.c-sidebar.c-sidebar-light.c-sidebar-minimized
  .c-sidebar-nav
  > .c-sidebar-nav-dropdown:hover {
  background: #321fdb;
}
.c-sidebar .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-primary {
  background: #321fdb;
}
.c-sidebar
  .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-primary .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.c-sidebar .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-primary:hover {
  background: #2d1cc5;
}
.c-sidebar
  .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle:hover
  .c-sidebar-nav-icon,
.c-sidebar
  .c-sidebar-nav-link.c-sidebar-nav-link-primary:hover
  .c-sidebar-nav-icon {
  color: #fff;
}
.c-sidebar .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-secondary {
  background: #ced2d8;
}
.c-sidebar
  .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-sidebar
  .c-sidebar-nav-link.c-sidebar-nav-link-secondary
  .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.c-sidebar .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-secondary:hover {
  background: #c0c5cd;
}
.c-sidebar
  .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle:hover
  .c-sidebar-nav-icon,
.c-sidebar
  .c-sidebar-nav-link.c-sidebar-nav-link-secondary:hover
  .c-sidebar-nav-icon {
  color: #fff;
}
.c-sidebar .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-success {
  background: #2eb85c;
}
.c-sidebar
  .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-success .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.c-sidebar .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-success:hover {
  background: #29a452;
}
.c-sidebar
  .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle:hover
  .c-sidebar-nav-icon,
.c-sidebar
  .c-sidebar-nav-link.c-sidebar-nav-link-success:hover
  .c-sidebar-nav-icon {
  color: #fff;
}
.c-sidebar .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-info {
  background: #39f;
}
.c-sidebar
  .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-info .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.c-sidebar .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-info:hover {
  background: #1a8cff;
}
.c-sidebar
  .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle:hover
  .c-sidebar-nav-icon,
.c-sidebar
  .c-sidebar-nav-link.c-sidebar-nav-link-info:hover
  .c-sidebar-nav-icon {
  color: #fff;
}
.c-sidebar .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-warning {
  background: #f9b115;
}
.c-sidebar
  .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-warning .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.c-sidebar .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-warning:hover {
  background: #eea506;
}
.c-sidebar
  .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle:hover
  .c-sidebar-nav-icon,
.c-sidebar
  .c-sidebar-nav-link.c-sidebar-nav-link-warning:hover
  .c-sidebar-nav-icon {
  color: #fff;
}
.c-sidebar .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-danger {
  background: #e55353;
}
.c-sidebar
  .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-danger .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.c-sidebar .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-danger:hover {
  background: #e23d3d;
}
.c-sidebar
  .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle:hover
  .c-sidebar-nav-icon,
.c-sidebar
  .c-sidebar-nav-link.c-sidebar-nav-link-danger:hover
  .c-sidebar-nav-icon {
  color: #fff;
}
.c-sidebar .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-light {
  background: #ebedef;
}
.c-sidebar
  .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-light .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.c-sidebar .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-light:hover {
  background: #dde0e4;
}
.c-sidebar
  .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle:hover
  .c-sidebar-nav-icon,
.c-sidebar
  .c-sidebar-nav-link.c-sidebar-nav-link-light:hover
  .c-sidebar-nav-icon {
  color: #fff;
}
.c-sidebar .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-dark {
  background: #636f83;
}
.c-sidebar
  .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-dark .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.c-sidebar .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-dark:hover {
  background: #586374;
}
.c-sidebar
  .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle:hover
  .c-sidebar-nav-icon,
.c-sidebar
  .c-sidebar-nav-link.c-sidebar-nav-link-dark:hover
  .c-sidebar-nav-icon {
  color: #fff;
}
/*# sourceMappingURL=sidebar.min.css.map */
