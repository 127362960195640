.btn {
    border-radius: 4px;
    padding: 0 12px;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
}

.btn-sm {
    border-radius: 4px;
    padding: 0 8px;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
}

.btn-danger {
    border: none;
    color: #FFF;
    background: #F7685B;
    box-shadow: 0 2px 6px rgba(84, 72, 203, 0.2);
}

.btn-warning {
    border: none;
    color: #FFF;
    background: rgba(255, 179, 2, 1);
    box-shadow: 0 2px 6px rgba(84, 72, 203, 0.2);
}

.btn-success {
    border: none;
    color: #FFF;
    background: rgba(46, 212, 121, 1);
    box-shadow: 0 2px 6px rgba(84, 72, 203, 0.2);
}

.btn-info {
    border: none;
    color: #FFF;
    background: #0060FF;
    box-shadow: 0 2px 6px rgba(84, 72, 203, 0.2);
}

.btn-black {
    border: none;
    color: #FFF;
    background: #000;
    box-shadow: 0 2px 6px rgba(84, 72, 203, 0.2);
}

.btn-primary {
    border: none;
    background: #2ED47A;
    color: #FFF;
    box-shadow: 0 2px 6px rgba(84, 72, 203, 0.2);
}

.btn-gray {
    border: none;
    background: gray;
    color: #FFF;
    box-shadow: 0 2px 6px rgba(84, 72, 203, 0.2);
}

.btn-default {
    border: none;
    background: #FFF;
    color: #000;
    box-shadow: 0 2px 6px rgba(84, 72, 203, 0.2);
}
