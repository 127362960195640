.modal-basic .danger-confrim {
  color: red;
}

.organizations .user-contents .modal-authority-settings {
  max-height: 500px;
  overflow: scroll;
}

.modal-consulting {
  min-width: 1000px;
}

.modal-consulting .tab {
  min-width: 80px !important;
  font-size: 12px !important;
}

.modal-consulting .tab .selected {
  font-weight: 700;
}

.modal-consulting .request-data {
  max-height: 200px;
}

.modal-consulting .request-data table {
  width: 100%;
  height: 100%;
  border: 1px solid #e9e9ed;
}

.modal-consulting .request-data th {
  color: #400e40;
}

.modal-consulting .responsive-history-data span,
.modal-consulting .unresponsive-data span {
  font-weight: 700;
}

.modal-consulting .handling-data {
  position: relative;
}

.modal-consulting .handling-data .dim {
  position: absolute;
  z-index: 100;
  height: 100px;
  width: 100%;
  background-color: hsla(0, 0%, 84.7%, 0.5);
  text-align: center;
}

.modal-consulting .handling-data .dim div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.modal-change-counselors table {
  min-width: 100%;
  text-align: center;
}

.modal-change-counselors table button {
  margin: auto;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.modal .modal-loading {
  color: #5f6367;
  text-align: center;
}

.modal .modal-loading .loading-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
  color: #38a4f8 !important;
  margin-bottom: 20px;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.modal,
.modal.hidden {
  transition: all 0.2s ease;
}

.modal.hidden {
  opacity: 0;
}

@media (min-width: 320px) {
  .modal-container {
    min-width: 320px;
  }
}

.modal .modal-container {
  min-width: 400px;
}

.modal .modal-container > div {
  position: relative;
  padding: 30px;
  border-radius: 4px;
}

.modal .modal-container > div .buttons button {
  min-width: 85px;
  height: 34px;
  margin-right: 8px;
}

.modal .modal-container > div .buttons button:not(:first-child) {
  margin-left: 0;
}

.modal .modal-container > div .buttons button:last-child {
  margin-right: 0;
}

.modal .modal-container i.zmdi-close {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0;
}

.modal .modal-container i.zmdi-close::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-image: url('../images/icon/ic-close.png');
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.modal .modal-container .head {
  position: relative;
  border-bottom: 0;
}

.modal .modal-container .head .title {
  max-width: calc(100% - 40px);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.08px;
  line-height: 1.4;
  color: #273142;
  word-break: keep-all;
}
.modal .modal-container .head .title:hover {
  background: #fff;
}

.modal .modal-container .head:after {
  content: '';
  display: block;
  position: absolute;
  left: -30px;
  bottom: 0;
  width: calc(100% + 60px);
  height: 1px;
  background-color: #d7e3f1;
}

.modal .modal-container .body .row {
  margin: 0;
}

.modal .modal-container .conts {
  min-height: 50px;
  padding: 30px 0 0;
  line-height: 1.36;
  color: #5f6367;
}

.modal .modal-container .conts p {
  line-height: inherit;
  color: inherit;
}

.modal .modal-container.modal-findpwd {
  min-width: 460px;
  max-width: 480px;
}

.modal .modal-container.modal-findpwd .head .title {
  font-size: 14px;
  font-weight: 500;
}

.modal .modal-container.modal-findpwd .head:after {
  display: none;
}

.modal .modal-container .data-table {
  padding: 0;
  border-radius: 4px;
  border: solid 1px #d7e3f1;
}

.modal .modal-container .data-table th,
.modal .modal-container .data-table td {
  width: auto;
  padding: 12px 0 12px;
  border-right: 0;
  color: #4a4f70;
}

.modal .modal-container .data-table th .item-content,
.modal .modal-container .data-table td .item-content {
  padding: 0;
}

.modal .modal-container .data-table th:not(:last-child),
.modal .modal-container .data-table td:not(:last-child) {
  border-right: 0;
}

.modal .modal-container > div .buttons button.btn-default {
  background-color: #fff;
  color: #000;
}

.modal .modal-container > div .buttons button.btn-primary {
  background-color: #0060ff;
}

.modal .modal-container .card {
  border-radius: 0;
}

.modal,
.modal.hidden {
  transition: all 0.2s ease;
}

.modal.hidden {
  opacity: 0;
}

@media (min-width: 320px) {
  .modal-container {
    min-width: 320px;
  }
}

.modal-container {
  word-wrap: break-word;
  max-width: calc(100vw - 32px);
  /* overflow: scroll;  */
}

.modal-container .head {
  font-size: 18px;
  line-height: 24px;
}

.modal-container .head .title {
  color: #000;
}

.modal-container .body {
  margin-top: 25px;
  font-size: 16px;
  line-height: 24px;
}

.modal-container i.zmdi-close {
  cursor: pointer;
}

.modal-container > div {
  padding: 20px 24px;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 96, 255, 0.1);
  border-radius: 0;
  max-height: calc(100vh - 64px);
  overflow-y: auto;
}

.modal-container > div .buttons {
  display: table;
  margin-top: 30px;
  margin-left: auto;
}

.modal-container > div .buttons button {
  min-width: 84px;
  border-radius: 0;
}

.modal-container > div .buttons button:not(:first-child) {
  margin-left: 16px;
}

.modal-enter,
.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}

.modal-input input,
.modal-input textarea {
  font-size: 12px;
  width: 100%;
}

.modal-input textarea {
  min-height: 120px;
}

.modal-images {
  padding: 0 !important;
  background: none !important;
  box-shadow: none !important;
}

.modal-images .image {
  width: 480px;
  height: 480px;
}

.modal-images .image img {
  width: 100%;
  height: 100%;
}

.modal-images .zmdi-chevron-left,
.modal-images .zmdi-chevron-right {
  color: #400e40;
  font-size: 64px;
  width: 64px;
}

.modal-images .zmdi-chevron-left:hover,
.modal-images .zmdi-chevron-right:hover {
  color: #200e20;
}

.modal-images .index {
  margin-top: 24px;
  font-size: 16px;
  justify-content: center;
}

.modal-images .index .item {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: #adacb0;
}

.modal-images .index .item:not(:last-child) {
  margin-right: 8px;
}

.modal-images .index .item.selected {
  background: #fff;
}

.modal-values .head .title {
  color: #400e40 !important;
  text-align: center;
  font-size: 16px;
  margin-bottom: 24px;
  width: 100%;
}

.modal-values .values {
  margin-bottom: 16px;
}

.modal-values .item .value {
  text-align: center;
  min-width: 96px;
}

.modal-values .item:not(:last-child) {
  margin-bottom: 8px;
}

.modal-change-password input {
  width: 100%;
}

.modal-change-password .form-control label {
  margin-bottom: 8px;
}

.modal-change-password .error-form {
  font-size: 12px;
  color: #ff3d6b;
}

.modal-treatment-item-category .treatment-item label {
  margin-bottom: 0;
}

.modal-treatment-item-category .treatment-item .price {
  max-width: 80px;
}

.modal-treatment-item-category .treatment-item .option {
  align-items: center;
}

.modal-treatment-item-category .treatment-item .option label {
  margin-right: 4px;
}

.modal-treatment-item-category .treatment-item .option:not(:last-child) {
  margin-right: 16px;
}

.modal-treatment-item-category .treatment-item:not(:last-child) {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e9e9ed;
}

.modal-treatment-item-category .section {
  border: 1px solid #400e40;
  padding: 16px;
}

.modal-treatment-item-category .section > label {
  font-size: 16px;
}

.modal-payment-products .payment-product {
  padding: 16px;
  border: 1px solid #400e40;
}

.modal-payment-products .payment-product:not(:last-child) {
  margin-top: 16px;
}

.modal-payment-products .total-amount {
  margin-top: 16px;
  font-size: 16px;
  color: #400e40;
}

.modal-deactivate-clinic .body {
  font-size: 12px;
}

.modal-edit-clinic-memo textarea {
  min-height: 160px;
  padding: 16px;
  width: 90%;
}

.modal-appointments .desc {
  margin: 16px 0;
  font-size: 12px;
  color: #400e40;
}

.modal-appointments .data-table table {
  display: block;
  overflow: auto;
}

.modal-container .modal-message-caller-number .body {
  font-size: 1em;
}

.modal-message-caller-number {
  width: 41em;
}

.modal-message-caller-number .policy-desc {
  background-color: #f7f7f7;
  border-radius: 5px;
  padding: 1.5em 0.9em 1em 1em;
  font-size: 0.8em;
  width: 45em;
  word-break: break-word;
}

.modal-message-caller-number .policy-desc li {
  list-style-type: disc;
}

.modal-message-caller-number .policy-desc li,
.modal-message-caller-number .policy-desc ul {
  margin-left: 1em;
  padding: 0;
  line-height: 2em;
}

.modal-message-caller-number input,
.modal-message-caller-number textarea {
  padding-left: 0.5em !important;
}

.modal-message-caller-number .response-desc {
  padding-left: 0.5em;
  font-size: 0.8em;
  color: #37b24d;
}

.modal-message-caller-number .response-desc .error,
.modal-message-caller-number .response-desc .fail {
  color: #f03e3e;
}

.modal-message-caller-number .response-desc .success {
  color: #37b24d;
}

.modal-message-caller-number .requesting {
  height: 100%;
}

.modal-message-caller-number .requesting .wait {
  display: flex;
  height: 100%;
}

.modal-message-caller-number .requesting .cancel {
  display: none;
  height: 100%;
}

.modal-message-caller-number .requesting:hover .wait {
  display: none;
}

.modal-message-caller-number .requesting:hover .cancel {
  display: flex;
}

.modal-message-caller-number .item-group > .row + .row {
  margin-top: 0.5em;
}

.modal-message-caller-number .mock-group {
  padding-left: 2em;
  background-color: #000;
  height: 7em;
}

.modal-message-caller-number .mock-group .mockapi-action {
  color: #fff;
}

.modal-message-caller-number .mock-group .mockapi-action button {
  color: #fff;
  border-color: #495057;
  border-radius: 3px;
  margin: 0 1em 0 0;
  height: 2.5em;
  cursor: pointer;
}

.modal-message-caller-number .mock-group .mockapi-action button.red {
  background-color: #c92a2a;
}

.modal-message-caller-number .mock-group .mockapi-action button.blue {
  background-color: #1864ab;
}

.modal-message-caller-number .mock-group .mockapi-action span {
  font-size: 0.7em;
}

.modal-message-caller-number .mock-group .mockapi-logs {
  font-size: 0.7em;
  color: #fff;
}

.modal-message-caller-number .mock-group .mockapi-logs.hidden {
  display: none;
  height: 0;
}

.modal-message-caller-number .mock-group .mockapi-logs span {
  font-size: 0.7em;
}

.modal-message-caller-number .mock-group .mockapi-logs p {
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 1em;
}

.modal-container > .modal-message-boilerplate-selector {
  width: 70em !important;
  max-height: 50em;
}

.modal-message-screen {
  max-height: none;
}

.modal-message-screen .body {
  margin-top: 20px;
}

.modal-message-screen .message-screen-auto-notification {
  width: 440px;
  font-size: 1.1em;
  height: 100vh;
  max-height: calc(100vh - 12em);
  border: 1px solid #adb5bd;
}

.modal-message-screen .message-screen-boilerplate {
  width: 460px;
  font-size: 1.1em;
  height: 100vh;
  max-height: calc(100vh - 17em);
  border: 1px solid #adb5bd;
}

.modal-message-screen .message-screen-boilerplate .title-input {
  width: 15em;
}

.modal-message-screen
  .message-screen-boilerplate
  .title-input
  input[name='category'] {
  font-size: 0.9em;
  padding-left: 0.5em;
  border-color: #f1f3f5;
}

.modal-message-screen
  .message-screen-boilerplate
  .title-input
  input[name='category']:focus {
  box-shadow: 1px 1px 4px 0 hsla(0, 3.3%, 70%, 0.56);
}

.modal-container .modal-pen-chart-sample {
  width: 980px;
  max-height: 90vh;
}

.modal-container .modal-img {
  display: inline-flex;
  padding: 0;
}

.modal-input input,
.modal-input textarea {
  font-size: 12px;
  width: 100%;
}

.modal-input textarea {
  min-height: 120px;
}

.modal-images {
  padding: 0 !important;
  background: none !important;
  box-shadow: none !important;
}

.modal-images .image {
  width: 480px;
}

.modal-images .image img {
  width: 100%;
  height: 100%;
}

.modal-images .zmdi-chevron-left,
.modal-images .zmdi-chevron-right {
  color: #400e40;
  font-size: 64px;
  width: 64px;
}

.modal-images .zmdi-chevron-left:hover,
.modal-images .zmdi-chevron-right:hover {
  color: #200e20;
}

.modal-images .index {
  margin-top: 24px;
  font-size: 16px;
  justify-content: center;
}

.modal-images .index .item {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: #adacb0;
}

.modal-images .index .item:not(:last-child) {
  margin-right: 8px;
}

.modal-images .index .item.selected {
  background: #fff;
}

.modal-values .head .title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 24px;
  width: 100%;
}

.modal-values .values {
  margin-bottom: 16px;
}

.modal-values .item .value {
  text-align: center;
  min-width: 96px;
}

.modal-values .item:not(:last-child) {
  margin-bottom: 8px;
}

.modal-change-password input {
  width: 100%;
}

.modal-change-password .form-control label {
  margin-bottom: 8px;
}

.modal-change-password .error-form {
  font-size: 12px;
  color: #ff3d6b;
}

.modal-treatment-item-category .treatment-item label {
  margin-bottom: 0;
}

.modal-treatment-item-category .treatment-item .price {
  max-width: 80px;
}

.modal-treatment-item-category .treatment-item .option {
  align-items: center;
}

.modal-treatment-item-category .treatment-item .option label {
  margin-right: 4px;
}

.modal-treatment-item-category .treatment-item .option:not(:last-child) {
  margin-right: 16px;
}

.modal-treatment-item-category .treatment-item:not(:last-child) {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e9e9ed;
}

.modal-treatment-item-category .section {
  border: 1px solid #400e40;
  padding: 16px;
}

.modal-treatment-item-category .section > label {
  font-size: 16px;
}

.modal-payment-products {
  width: 868px;
}

.modal .modal-container .modal-payment-products.open-chart .head .title {
  font-size: 14px;
}
.modal .modal-container .modal-payment-products.open-chart .data-table th,
.modal .modal-container .modal-payment-products.open-chart .data-table td,
.modal
  .modal-container
  .modal-payment-products.open-chart
  div
  .buttons
  button.btn-primary {
  font-size: 12px;
}

.modal-payment-products .payment-product {
  padding: 16px;
  border: 1px solid #400e40;
}

.modal-payment-products .payment-product:not(:last-child) {
  margin-top: 16px;
}

.modal-payment-products .total-amount {
  margin-top: 16px;
  font-size: 16px;
  color: #400e40;
}

.modal-deactivate-clinic .body {
  font-size: 12px;
}

.modal-edit-clinic-memo textarea {
  min-height: 160px;
}

.modal-appointments .desc {
  margin: 16px 0;
  font-size: 12px;
  color: #400e40;
}

.modal-appointments .data-table table {
  display: block;
  overflow: auto;
}

.customer-deposits .list-inline {
  list-style: none;
  display: block;
}

.customer-deposits .list-inline .list-inline-item {
  display: inline-block;
}

.customer-deposits .list-inline .list-inline-item.summary-amount {
  margin: 1em 3em 0 1em;
}

.customer-deposits .list-inline .list-inline-item.summary-amount span {
  font-size: 2em;
}

.customer-deposits .list-inline .list-inline-item.summary-cash,
.customer-deposits .list-inline .list-inline-item.summary-points {
  padding: 0 1em;
}

.customer-deposits .list-inline .list-inline-item.summary-cash span,
.customer-deposits .list-inline .list-inline-item.summary-points span {
  font-size: 1.2em;
}

.customer-deposits .list-inline .list-inline-item.summary-cash {
  border-right-color: #101010;
  border-right-width: 1px;
  border-right-style: solid;
}

.modal-deposit-charge .flex-row .form-control:nth-child(odd) {
  margin-right: 0.5em;
}

.modal-container .modal-message-caller-number .body {
  font-size: 1em;
}

.modal-message-caller-number {
  width: 41em;
}

.modal-message-caller-number .policy-desc {
  background-color: #f7f7f7;
  border-radius: 5px;
  padding: 1.5em 0.9em 1em 1em;
  font-size: 0.8em;
  width: 45em;
  word-break: break-word;
}

.modal-message-caller-number .policy-desc li {
  list-style-type: disc;
}

.modal-message-caller-number .policy-desc li,
.modal-message-caller-number .policy-desc ul {
  margin-left: 1em;
  padding: 0;
  line-height: 2em;
}

.modal-message-caller-number input,
.modal-message-caller-number textarea {
  padding-left: 0.5em !important;
}

.modal-message-caller-number .response-desc {
  padding-left: 0.5em;
  font-size: 0.8em;
  color: #37b24d;
}

.modal-message-caller-number .response-desc .error,
.modal-message-caller-number .response-desc .fail {
  color: #f03e3e;
}

.modal-message-caller-number .response-desc .success {
  color: #37b24d;
}

.modal-message-caller-number .requesting {
  height: 100%;
}

.modal-message-caller-number .requesting .wait {
  display: flex;
  height: 100%;
}

.modal-message-caller-number .requesting .cancel {
  display: none;
  height: 100%;
}

.modal-message-caller-number .requesting:hover .wait {
  display: none;
}

.modal-message-caller-number .requesting:hover .cancel {
  display: flex;
}

.modal-message-caller-number .item-group > .row + .row {
  margin-top: 0.5em;
}

.modal-message-caller-number .mock-group {
  padding-left: 2em;
  background-color: black;
  height: 7em;
}

.modal-message-caller-number .mock-group .mockapi-action {
  color: white;
}

.modal-message-caller-number .mock-group .mockapi-action button {
  color: white;
  border-color: #495057;
  border-radius: 3px;
  margin: 0 1em 0 0;
  height: 2.5em;
  cursor: pointer;
}

.modal-message-caller-number .mock-group .mockapi-action button.red {
  background-color: #c92a2a;
}

.modal-message-caller-number .mock-group .mockapi-action button.blue {
  background-color: #1864ab;
}

.modal-message-caller-number .mock-group .mockapi-action span {
  font-size: 0.7em;
}

.modal-message-caller-number .mock-group .mockapi-logs {
  font-size: 0.7em;
  color: white;
}

.modal-message-caller-number .mock-group .mockapi-logs.hidden {
  display: none;
  height: 0;
}

.modal-message-caller-number .mock-group .mockapi-logs span {
  font-size: 0.7em;
}

.modal-message-caller-number .mock-group .mockapi-logs p {
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 1em;
}

.modal-message-screen {
  max-height: none;
}

.modal-message-screen .body {
  margin-top: 20px;
}

.modal-message-screen.auto-notification div.buttons,
.modal-message-screen.boilerplate div.buttons {
  margin-top: 15px;
}

.modal-message-screen .message-screen-auto-notification {
  width: 440px;
  font-size: 1em;
  height: calc(100vh);
  max-height: calc(100vh - 12em);
  border-color: #adb5bd;
  border-style: solid;
  border-width: 1px;
}

@media screen and (max-height: 900px) {
  .modal-message-screen .message-screen-auto-notification {
    max-height: calc(100vh - 11em);
  }
}

.modal-message-screen .message-screen-boilerplate {
  width: 460px;
  font-size: 1em;
  height: calc(100vh);
  max-height: calc(100vh - 17em);
  border-color: #adb5bd;
  border-style: solid;
  border-width: 1px;
}

@media screen and (max-height: 900px) {
  .modal-message-screen .message-screen-boilerplate {
    max-height: calc(100vh - 11em);
  }
}

.modal-message-screen .message-screen-boilerplate .title-input {
  width: 15em;
}

.modal-message-screen
  .message-screen-boilerplate
  .title-input
  input[name='category'] {
  font-size: 0.9em;
  padding-left: 0.5em;
  border-color: #f1f3f5;
}

.modal-message-screen
  .message-screen-boilerplate
  .title-input
  input[name='category']:focus {
  box-shadow: 1px 1px 4px 0 rgba(181, 176, 176, 0.56);
}

.modal-message-target-search-history-selector
  .column-search-condition
  .contents {
  width: 100%;
  padding-left: 1em;
}

.modal-message-target-search-history-selector
  .column-search-condition
  .contents
  .row-group {
  border: 1px solid #f1f3f5;
  padding: 0.5em 1em;
}

.modal-message-target-search-history-selector
  .column-search-condition
  .contents
  .row-group
  .row-condition {
  display: flex;
}

.modal-message-target-search-history-selector
  .column-search-condition
  .contents
  .row-group
  .row-condition
  .label {
  display: flex;
  min-width: 13em;
  border-right: 1px solid #f1f3f5;
}

.modal-message-target-search-history-selector
  .column-search-condition
  .contents
  .row-group
  .row-condition
  .label
  .category {
  padding-right: 0.5em;
}

.modal-message-target-search-history-selector
  .column-search-condition
  .contents
  .row-group
  .row-condition
  .query {
  display: flex;
  padding-left: 1em;
}

.modal-message-target-search-history-selector
  .column-search-condition
  .contents
  .row-group
  + .group-separator {
  display: flex;
  width: 100%;
  justify-content: center;
}

.modal-message-target-search-history-selector
  .column-search-condition
  .contents
  .group-separator {
  display: none;
}

.modal-message-target-search-history-selector .column-memo button:hover {
  cursor: pointer;
}

.modal-message-target-search-history-selector .column-memo button.has-value {
  background: none;
  border: none;
  text-decoration: underline;
}

.error-manual-input-number .row {
  display: flex;
}

.error-manual-input-number .row .label {
  flex-grow: 1;
  display: flex;
  font-weight: bold;
}

.error-manual-input-number .row .label .count {
  display: flex;
  padding-left: 0.5em;
  color: #ff6b6b;
}

.error-manual-input-number .row .detail {
  flex-grow: 1;
  justify-self: flex-start;
  font-size: 0.9em;
  margin-left: 3em;
  display: flex;
  flex-direction: column;
}

.error-manual-input-number .row + .row {
  border-top: 1px solid #dee2e6;
  padding-top: 1em;
}

.error-manual-input-number .row:last-child {
  padding-right: 1em;
  place-content: flex-end;
  text-align: right;
  margin-bottom: -30px;
}

.modal .modal-container .modal-message-send.message-send {
  width: 1200px;
}

.modal-message-send.message-send .point-status {
  min-height: auto;
}

.modal-message-send.message-send .point-status .point-head,
.modal-message-send.message-send .point-status .message-screen-message-send {
  padding-left: 0;
}

.modal-message-send.message-send .card {
  border: 0;
}

.modal-message-send .point-status .btnwrap {
  padding: 0 20px 0 0;
}

.modal-message-send-confirm {
  width: 30em;
}

.modal-message-send-confirm .body {
  margin: 1em 1em 1em 0.5em;
}

.modal-message-send-confirm .row {
  padding: 0;
}

.modal-message-send-confirm .row .label {
  margin-left: 1em;
  padding-right: 0.5em;
}

.modal-message-send-confirm .row .value {
  font-weight: bold;
}

.modal-message-send-confirm .row.guide {
  background-color: #f1f3f5;
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #e9ecef;
  width: 100%;
  white-space: break-spaces;
  font-size: 0.8em;
  line-height: 1.4em;
}

.modal-message-send-confirm .row + .row {
  padding-top: 0.5em;
}

.modal-message-details.message-type-event .search-inputs {
  display: flex;
  max-width: 30em;
}

.modal-message-details.message-type-event .search-inputs input,
.modal-message-details.message-type-event .search-inputs select {
  min-width: 8em;
}

.modal-message-details.message-type-event .search-inputs .btn {
  min-width: 5em;
}

.modal-container .modal-pen-chart-sample {
  width: 980px;
  max-height: 90vh;
}

.modal-container .modal-img {
  display: inline-flex;
  padding: 0;
}

.modal-container .modal-status-categories .tabs {
  font-size: 16px;
}

.modal-container .modal-status-categories .tab-contents {
  border: solid 1px #e9e9ed;
}

.modal-container .modal-status-categories .contents {
  padding: 50px;
}

.modal-container .modal-status-categories div {
  align-items: center;
}

.modal-container .modal-status-categories .fail-text {
  color: red;
}

.modal-container .modal-status-categories .fail-text.disabled span {
  display: none;
}

.modal-container .modal-status-categories .right {
  float: right;
}

.modal-container .modal-monthly-settlements-detail {
  width: 1300px;
}

.modal-message-screen .message-screen-map {
  width: 440px;
  font-size: 1em;
  height: calc(100vh);
  max-height: calc(100vh - 17em);
  border-color: #adb5bd;
  border-style: solid;
  border-width: 1px;
}

@media screen and (max-height: 900px) {
  .modal-message-screen .message-screen-map {
    max-height: calc(100vh - 11em);
  }
}

.modal .modal-complete-auto-sms .data-table td,
.modal .modal-complete-update-auto-sms .data-table td {
  font-size: 12px;
}

.modal .modal-complete-auto-sms .data-table td.title-and-content .item-content,
.modal
  .modal-complete-update-auto-sms
  .data-table
  td.title-and-content
  .item-content {
  font-size: 12px;
  margin: 0.5em 0;
  padding: 0;
  white-space: initial;
  justify-content: flex-start;
  padding-left: 1em;
  text-align: left;
}

.modal
  .modal-complete-auto-sms
  .data-table
  td.title-and-content
  .item-content
  label,
.modal
  .modal-complete-update-auto-sms
  .data-table
  td.title-and-content
  .item-content
  label {
  flex: 0 0 auto;
}

.modal
  .modal-complete-auto-sms
  .data-table
  td.title-and-content
  .item-content
  .title,
.modal
  .modal-complete-auto-sms
  .data-table
  td.title-and-content
  .item-content
  .contents,
.modal
  .modal-complete-update-auto-sms
  .data-table
  td.title-and-content
  .item-content
  .title,
.modal
  .modal-complete-update-auto-sms
  .data-table
  td.title-and-content
  .item-content
  .contents {
  display: flex;
  flex-direction: row;
  margin-right: 1em;
  white-space: pre-line;
  border: 1px solid #f1f3f5;
}

.modal
  .modal-complete-auto-sms
  .data-table
  td.title-and-content
  .item-content
  .title
  .label,
.modal
  .modal-complete-auto-sms
  .data-table
  td.title-and-content
  .item-content
  .contents
  .label,
.modal
  .modal-complete-update-auto-sms
  .data-table
  td.title-and-content
  .item-content
  .title
  .label,
.modal
  .modal-complete-update-auto-sms
  .data-table
  td.title-and-content
  .item-content
  .contents
  .label {
  font-weight: 600;
  padding: 1em;
  min-width: 2em;
  align-self: center;
}

.modal
  .modal-complete-auto-sms
  .data-table
  td.title-and-content
  .item-content
  .title
  .text,
.modal
  .modal-complete-auto-sms
  .data-table
  td.title-and-content
  .item-content
  .contents
  .text,
.modal
  .modal-complete-update-auto-sms
  .data-table
  td.title-and-content
  .item-content
  .title
  .text,
.modal
  .modal-complete-update-auto-sms
  .data-table
  td.title-and-content
  .item-content
  .contents
  .text {
  border-left: 1px solid #e9ecef;
  padding: 1em;
  justify-content: center;
}

.modal
  .modal-complete-auto-sms
  .data-table
  td.title-and-content
  .item-content
  .title
  + .contents,
.modal
  .modal-complete-update-auto-sms
  .data-table
  td.title-and-content
  .item-content
  .title
  + .contents {
  border-top: 0;
}

.modal .modal-complete-update-auto-sms > div.buttons button,
.modal .modal-complete-update-auto-sms-alert > div.buttons button {
  font-size: 12px;
}

.modal .modal-complete-update-auto-sms-alert {
  font-size: 12px;
}

.modal .modal-complete-update-auto-sms-alert .title {
  margin-bottom: 32px;
  font-size: 14px;
  font-weight: 700;
}

.modal .modal-complete-update-auto-sms {
  width: 900px;
  font-size: 12px;
}

.modal .modal-complete-update-auto-sms .sub-title {
  font-size: 14px;
  font-weight: 700;
  color: #4a5070;
}

.modal .modal-complete-update-auto-sms span {
  font-weight: 500;
}

.modal .modal-complete-update-auto-sms span.red-content {
  color: #f7685b;
}

.modal .modal-create-seal .seal-contents {
  align-items: center;
}

.modal .modal-create-seal .seal-contents .seal-preview {
  width: 40px;
  height: 40px;
  background-size: 40px 40px;
  background-repeat: 'no-repeat';
  background-position: 'center center';
}

.modal .modal-container .customer-payments .header-toggle-btn {
  width: 55px;
  height: 20px;
  padding: 0 0;
  border-radius: 18px !important;
  border: solid 1px #dee2ec;
  background-color: #ffffff !important;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  box-shadow: none;
  color: #ffffff;
  justify-content: flex-start;
}

.toggle-btn {
  display: flex;
  width: 55px;
  height: 20px;
  padding: 0 0;
  border-radius: 18px !important;
  border: solid 1px #dee2ec;
  background-color: #ffffff !important;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  box-shadow: none;
  color: #ffffff;
  justify-content: flex-start !important;
}

.toggle-btn.selected {
  display: flex;
  justify-content: flex-end !important;
}
.modal .modal-container .customer-payments div.toggle {
  margin-top: 30px;
  margin-bottom: 20px;
}

.modal .modal-container .customer-payments .header-toggle-btn.selected {
  justify-content: flex-end;
  display: flex;
}

.modal .modal-container .customer-payments .header-toggle-btn label {
  width: 30px;
  height: 30px;
  border-radius: 18px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4a4f70;
  top: -5px;
  position: relative;
}

.toggle-btn label {
  width: 30px;
  height: 20px;
  border-radius: 18px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4a4f70;
  top: -5px;
  position: relative;
  padding-top: 8px !important;
  font-size: 10px !important;
  text-align: center !important;
}

.head {
  padding-bottom: 20px;
}

.items-start input:not([type='radio']):not([type='checkbox']) {
  width: 270px !important;
  min-height: 10px;
  height: 34px;
  border-radius: 4px;
  font-size: 14px;
  color: #000;
  padding: 8px 15px 8px 15px;
  border: 1px solid #dee2ec;
  background-color: #f3f8ff;
  background-size: 19px;
  background-repeat: no-repeat;
  background-position: left 15px center;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.items-start input:not([type='radio']):not([type='checkbox'])::placeholder {
  color: #adacb0;
}

.search-item {
  vertical-align: middle;
  margin-right: 10px;
  margin-top: 4px;
}

table {
  margin-top: 20px;
  background: #fff;
  border-spacing: 0;
  width: 100%;
  overflow: hidden;
  border-collapse: separate;
  text-indent: initial;
  border: solid 1px #dee2ec;
  border-radius: 4px;
}

table th {
  font-family: NotoSans, sans-serif;
  color: #4a4f70;
  font-size: 14px;
  font-weight: 600;
  display: table-cell;
  vertical-align: inherit;
  text-align: center;
  padding: 20px;
  overflow: hidden;
}

table td {
  font-family: NotoSans, sans-serif;
  color: #4a4f70;
  font-size: 14px;
  font-weight: 500;
  display: table-cell;
  vertical-align: inherit;
  text-align: center;
  padding: 15px 5px 15px 5px;
  border-top: solid 1px #dee2ec;
  overflow: auto;
}

table .td-align-left {
  text-align: left;
}

table .td-underline {
  text-decoration: #4a4f70 underline;
}

.form-control {
  margin-bottom: 16px;
}

.form-control label {
  display: block;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  font-weight: bold;
  margin-bottom: 8px;
}

.form-control.disable label {
  color: #9cb2cd;
}

.form-control .help {
  font-size: 12px;
  color: #400e40;
}

.label-required label:after {
  color: #ff3d6b;
  content: '*';
}

.form-control select {
  border-radius: 4px;
  border: 1px solid #dee2ec;
  font-size: 14px;
  line-height: 1;
  padding: 0 10px;
  max-width: 450px;
  text-overflow: ellipsis;
  width: 100%;
  height: 34px;
}

.modal .modal-container .form-control {
  position: relative;
}

.form-control input:not([type='radio']):not([type='checkbox']) {
  max-width: 450px !important;
  height: 34px;
  border-radius: 4px;
  font-size: 14px;
  color: #000;
  padding: 8px 15px 8px 15px;
  border: 1px solid #dee2ec;
  background-size: 19px;
  background-repeat: no-repeat;
  background-position: left 15px center;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.form-control input:not([type='radio']):not([type='checkbox']):disabled {
  background-color: #efeff4;
}

.form-control input:not([type='radio']):not([type='checkbox'])::placeholder {
  color: #adacb0;
}

.modal-payment-payouts .form-control input {
  width: 100%;
}

.modal-payment-payouts .form-control .react-datepicker-wrapper {
  width: 100%;
}

.modal-clinic-input-form {
  min-width: 800px;
}
.modal-clinic-deposit-condition {
  min-width: 1200px;
}

.modal-clinic-use-deposit-input-form {
  min-width: 400px;
}

.modal-clinic-input-form input:not([type='radio']):not([type='checkbox']) {
  width: 350px;
}

.modal-clinic-input-form select {
  width: 350px;
}

.modal-clinic-input-form div {
  max-width: 800px;
}

.modal-clinic-input-form .upload {
  width: 350px;
  border: 1px solid #000;
  margin-bottom: 5px;
}

.upload .file-name {
  font-size: 14px;
  font-weight: 500;
  margin: 5px 5px 10px 5px;
}

.upload .help {
  color: red;
  margin: 5px 5px 5px 5px;
}

.modal-clinic-input-form .form-control .required label {
  font-weight: 500;
}

.modal-clinic-input-form .card-title {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 10px;
}
