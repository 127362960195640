.login.main {
    width: 100vw;
    height: 100vh;
    display: table-cell;
    vertical-align: middle;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    align-items: center;
    background-image: url('../../images/main_imgage.jpg');
    overflow-y: auto;
}

.login.UNOBEAUTY-CRM {
    width: 100%;
    height: 120px;
    margin-bottom: 100px;
    font-family: AppleSDGothicNeo;
    font-size: 50px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 5px;
    text-align: center;
    color: #ffffff;
}

.login.loginForm {
    text-align: center;
}

.login.loginForm input {
    height: 73px;
    padding-left: 91px;
    padding-right: 39px;
    border-radius: 45px;
    border: solid 1px #fff;
    background-color:transparent;
    outline:none;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    color: #fff;
    -webkit-text-fill-color: #fff !important;
}

.login.loginForm .id {
    background-image: url('../../images/routes/Login/loginInputId.png') !important;
    background-repeat: no-repeat;
    background-position: 5px center;
}

.login.loginForm .password {
    margin-top: 27px;
    margin-bottom: 57px;
    background-image: url('../../images/routes/Login/loginInputPw.png') !important;
    background-repeat: no-repeat;
    background-position: 5px center;
}

.login.loginForm input::-webkit-input-placeholder {
    margin: 3px 0 1px 18px;
    opacity: 0.4;
    font-family: AppleSDGothicNeo;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.09px;
    color: #fff;
}

.login.loginForm input:-webkit-autofill,
.login.loginForm input:-webkit-autofill:hover,
.login.loginForm input:-webkit-autofill:focus,
.login.loginForm input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    background-image: url('../../images/routes/Login/loginInputPw.png');
    -webkit-transition: background-color 9999s ease-out;
    -webkit-text-fill-color: #fff !important;
}

.login.loginForm button {
    width: 400px;
    height: 73px;
    border-radius: 45px;
    border: 0;
    background-color: #897dff;
    font-family: AppleSDGothicNeo;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    margin-bottom: 105px;
}

.login.bottom {
    font-family: AppleSDGothicNeo;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.login.bottom.first {
    margin-bottom: 12px;
}